<template>
  <div>
    <p class="mb-6 text-gray-500 text-sm">{{ $t('firewall.edit.servers-description') }}</p>

    <div class="mt-6">
      <oh-button @click="showAddServerModal()" type="primary" class="mt-8">
        <i class="fas fa-plus pr-1"></i>
        {{ $t('firewall.edit.add-server') }}
      </oh-button>
    </div>

    <table class="text-left w-full mt-6">
      <thead>
      <tr class="border-b border-gray-400">
        <th class="px-3 py-3">{{ $t('firewall.edit.server-name') }}</th>
        <th class="px-3 py-3 hidden md:table-cell">{{ $t('firewall.edit.server-ip') }}</th>
        <th class="px-3 py-3 hidden md:table-cell">{{ $t('firewall.edit.server-status') }}</th>
        <th class="px-3 py-3"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="customerProduct in firewall.assigned_customer_products" :key="customerProduct.id"
          class="hover:bg-gray-100 border-b border-gray-200">
        <td class="px-3 py-3 underline">
          <router-link :to="'/servers/' + customerProduct.id">{{ customerProduct.name }}</router-link>
        </td>
        <td class="px-3 py-3 hidden md:table-cell">{{ customerProduct.ip }}</td>
        <td class="px-3 py-3">
          <div v-if="customerProduct.status === 'active'">
            <i class="fas fa-circle text-green-500 mr-1"></i>
            {{ $t('firewall.edit.status-enabled') }}
          </div>
          <div v-else-if="customerProduct.status === 'assigning'">
            <i class="fas fa-circle text-blue-500 mr-1"></i>
            {{ $t('firewall.edit.status-assigning') }}
          </div>
          <div v-else-if="customerProduct.status === 'removing'">
            <i class="fas fa-circle text-red-500 mr-1"></i>
            {{ $t('firewall.edit.status-removing') }}
          </div>
        </td>
        <td class="px-3 py-3 text-right">
          <oh-button @click="toggleUnassign(customerProduct)"
                     :type="customerProduct.status === 'removing' ? 'default' : 'danger-outline'">
            {{
              customerProduct.status === 'removing' ? $t('firewall.edit.revert-remove-server') : $t('firewall.edit.remove-server')
            }}
          </oh-button>
        </td>
      </tr>
      <tr v-if="firewall.assigned_customer_products.length === 0" class="hover:bg-gray-100 border-b border-gray-200">
        <td class="px-3 py-3" colspan="4">{{ $t('firewall.edit.no-servers') }}</td>
      </tr>
      </tbody>
    </table>

    <div v-if="isDirty" class="text-oh-red mt-6">
      <i class="fas fa-exclamation-triangle text-oh-red"></i>
      {{ $t('firewall.edit.unsaved-changes') }} {{ $t('firewall.edit.unsaved-changes-hint') }}
    </div>

    <modal :show="addServerModal" @close="addServerModal = false">
      <template slot="title">
        {{ $t('firewall.add-server.headline') }}
      </template>

      <table class="text-left w-full overflow-y-auto">
        <thead>
        <tr class="border-b border-gray-400">
          <th></th>
          <th class="px-3 py-3">{{ $t('firewall.edit.server-name') }}</th>
          <th class="px-3 py-3 hidden md:table-cell">{{ $t('firewall.edit.server-ip') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="customerProduct in unassignedCustomerProducts" :key="customerProduct.id"
            class="hover:bg-gray-100 border-b border-gray-200 cursor-pointer"
            :class="{'bg-gray-100': selectedServers.includes(customerProduct.id)}"
            @click.stop="toggleServer(customerProduct)">
          <td class="px-3 py-3">
            <input type="checkbox" @click.stop="toggleServer(customerProduct)"
                   :checked="selectedServers.includes(customerProduct.id)">
          </td>
          <td class="px-3 py-3">{{ customerProduct.settings.name }}</td>
          <td class="px-3 py-3 hidden md:table-cell">{{ customerProduct.settings.ipAddresses[0].ip }}</td>
        </tr>
        <tr v-if="unassignedCustomerProducts.length === 0" class="hover:bg-gray-100 border-b border-gray-200">
          <td class="px-3 py-3" colspan="3">{{ $t('firewall.add-server.no-unassigned') }}</td>
        </tr>
        </tbody>
      </table>

      <div class="mt-6 text-center">
        <oh-button @click="assignServers()" :type="selectedServers.length ? 'primary' : 'disabled'">
          {{ $t('firewall.add-server.add') }}
        </oh-button>
      </div>
    </modal>
  </div>
</template>

<script>

import OhButton from "@/components/OhButton.vue";
import Modal from "@/components/Modal.vue";
import {globalData} from "@/globalData";

export default {
  components: {Modal, OhButton},
  props: {
    firewall: {
      required: true
    },
    isDirty: {
      required: true
    }
  },
  data() {
    return {
      addServerModal: false,
      selectedServers: []
    };
  },
  methods: {
    showAddServerModal() {
      this.addServerModal = true
      this.selectedServers = []
    },
    toggleServer(customerProduct) {
      if (this.selectedServers.includes(customerProduct.id)) {
        this.selectedServers = this.selectedServers.filter(id => id !== customerProduct.id)
      } else {
        this.selectedServers.push(customerProduct.id)
      }
    },
    assignServers() {
      if (!this.selectedServers.length) {
        return
      }
      this.firewall.assigned_customer_products.push(...globalData.products.filter(product => this.selectedServers.includes(product.id))
          .map(product => ({
            id: product.id,
            name: product.settings.name,
            ip: product.settings.ipAddresses[0].ip,
            status: 'assigning'
          }))
      )

      this.addServerModal = false
      this.selectedServers = []
    },
    toggleUnassign(customerProduct) {
      if (customerProduct.status === 'removing') {
        customerProduct.status = 'active'
      } else if (customerProduct.status !== 'active') {
        this.firewall.assigned_customer_products = this.firewall.assigned_customer_products.filter(assignedProduct => assignedProduct.id !== customerProduct.id)
      } else {
        customerProduct.status = 'removing'
      }
    }
  },
  computed: {
    unassignedCustomerProducts() {
      return (globalData.products || []).filter(product => {
        return !this.firewall.assigned_customer_products.some(assignedProduct => assignedProduct.id === product.id)
            && product.settings.ipAddresses
            && product.product.category === 'primary'
            && product.firewall === null
      }).sort((a, b) => a.settings.name.localeCompare(b.settings.name))
    }
  }
}
</script>

<style scoped>
.rule-table {
  min-width: 800px;
}
</style>