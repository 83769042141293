var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"px-6 py-2 rounded-lg transition",class:[
        _vm.type === 'default' ? 'border bg-white border-gray-300 hover:bg-gray-300': '',
        _vm.type === 'danger' ? 'bg-oh-red text-white hover:bg-red-900': '',
        _vm.type === 'danger-outline' ? 'border border-oh-red text-oh-red bg-white': '',
        _vm.type === 'warning' ? 'bg-oh-yellow text-black hover:bg-yellow-500': '',
        _vm.type === 'primary' ? 'bg-oh-gradient text-white': '',
        _vm.type === 'disabled' ? 'bg-gray-200 text-gray-700 cursor-not-allowed': 'cursor-pointer hover:shadow-xl',
        _vm.buttonClass
    ],attrs:{"href":_vm.href || '#',"role":"button"},on:{"click":_vm.onClick}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }