<template>
  <main-layout :loading="loading">
    <template slot="title" v-if="!loading">
      {{ $t('firewall.edit.headline', [firewall.name]) }}
    </template>

    <div class="min-h-full" v-if="!loading">
      <div class="m-auto max-w-7xl">
        <card class="w-full">
          <template slot="title">
            <div class="flex">
              <div class="flex-1">
                {{ $t('firewall.edit.title') }}
              </div>
              <a :href="$t('help-link') + 'firewall-usage'" target="_blank">
                <i class="far fa-question-circle text-2xl mr-2 text-oh-red"></i>
              </a>
            </div>
          </template>

          <div v-if="message"
               class="p-6 mb-5 font-bold" :class="{
              'bg-red-100': messageType === 'error',
              'bg-green-100': messageType === 'success',
              'bg-blue-100': messageType === 'info'
           }">
            {{ message }}
          </div>

          <div class="text-gray-500 text-sm">{{ $t('firewall.edit.teaser') }}</div>
          <div class="flex mt-8">
            <div class="flex-1">
              <div class="md:flex mb-3">
                <label class="block py-3 pr-6 font-bold">{{ $t('firewall.edit.name') }}</label>
                <div class="flex-1">
                  <input type="text" v-model="firewall.name"
                         :placeholder="$t('firewall.edit.name-placeholder')"
                         class="border border-gray-300 appearance-none rounded-md w-full p-3 focus:border-oh-blue focus:outline-none active:outline-none active:border-oh-blue focus:shadow-input">
                </div>
              </div>
            </div>
          </div>

          <div class="flex mt-8">
            <div>
              <oh-button @click="save()" type="primary" class="text-center">
                {{ $t('firewall.edit.save') }}
              </oh-button>
            </div>
            <div class="flex-1">
              <div v-if="isDirty" class="text-oh-red ml-4">
                <i class="fas fa-exclamation-triangle text-oh-red"></i>
                {{ $t('firewall.edit.unsaved-changes') }}
              </div>
            </div>
            <div v-if="firewall.id">
              <oh-button @click="confirmDelete()" type="danger-outline" class="text-center ml-3">
                {{ $t('firewall.edit.delete') }}
              </oh-button>
            </div>
          </div>
        </card>

        <tab-card :tabs="tabs" :initial-selection="currentTab" @selected="selectTab">
          <div class="mt-4">
            <div v-if="currentTab === 'rules'">
              <firewall-config :firewall="firewall" :is-dirty="isDirty"></firewall-config>
            </div>
            <div v-if="currentTab === 'servers'">
              <firewall-servers :firewall="firewall" :is-dirty="isDirty"></firewall-servers>
            </div>
          </div>
        </tab-card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import {globalData} from "@/globalData";
import {api} from "@/api";
import Card from "@/components/Card.vue";
import FirewallConfig from "@/components/Firewall/FirewallConfig.vue";
import TabCard from "@/components/TabCard.vue";
import OhButton from "@/components/OhButton.vue";
import FirewallServers from "@/components/Firewall/FirewallServers.vue";

const DEFAULT_RULES = [
  {comment: 'Default', ports: '22', position: 1, protocol: 'tcp', type: 'inbound'},
  {comment: 'Default', ports: '88,5900', position: 2, protocol: 'tcp', type: 'inbound'},
  {comment: 'Default', ports: null, position: 3, protocol: 'icmp', type: 'outbound'},
  {comment: 'Default', ports: null, position: 4, protocol: 'tcp', type: 'outbound'},
  {comment: 'Default', ports: null, position: 5, protocol: 'udp', type: 'outbound'}
];

export default {
  props: {
    id: {
      required: false,
    },
    productId: {
      required: false,
    }
  },
  data() {
    return {
      firewall: null,
      message: null,
      messageType: null,
      loading: true,
      shouldSyncProperty: true,
      currentTab: 'rules',
      liveFirewall: '',
      isDirty: false,
      tabs: {
        rules: {icon: 'fas fa-list', name: this.$t('firewall.edit.tab-rules')},
        servers: {icon: 'fas fa-link', name: this.$t('firewall.edit.tab-servers')}
      }
    };
  },
  async mounted() {
    globalData.activeRoute = 'firewall';

    if (this.id) {
      await this.loadFirewall();
    } else {
      this.prepareEmptyFirewall();
    }
    this.loading = false;
    this.liveFirewall = JSON.stringify(this.firewall);

    window.addEventListener('beforeunload', this.notifyOnDirty);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.notifyOnDirty);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !window.confirm(this.notifyOnDirty())) {
      next(false);
    } else {
      next();
    }
  },
  methods: {
    prepareEmptyFirewall() {
      this.firewall = {
        name: '',
        rules: DEFAULT_RULES,
        assigned_customer_products: []
      }
    },
    async loadFirewall() {
      this.firewall = (await api.get('user/firewalls/' + this.id)).data;
    },
    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.message = null;
      try {
        let response = null
        if (this.firewall.id) {
          response = await api.put('user/firewalls/' + this.firewall.id, this.firewall);
        } else {
          response = await api.post('user/firewalls', this.firewall);
        }

        if (response.data.assigned_customer_products.length === 0) {
          this.messageType = 'info';
          this.message = this.$t('servers.fw.success-no-servers');
        } else {
          this.messageType = 'success';
          this.message = this.$t('servers.fw.success');
        }
        this.firewall.id = response.data.id;
        this.firewall.rules = response.data.rules;
        this.firewall.assigned_customer_products = response.data.assigned_customer_products;
        this.liveFirewall = JSON.stringify(this.firewall);
        // reload products to update the list of assigned products
        globalData.products = (await api.get('user/products')).data;
      } catch (error) {
        this.catchError(error);
      }
      this.loading = false;
    },
    catchError(error) {
      this.messageType = 'error';
      if (error.response.data && error.response.data.errors) {
        let errors = error.response.data.errors;
        this.message = errors[Object.keys(errors)[0]][0];
      } else {
        this.message = 'Unknown error. Please try again later or contact support.';
      }
    },
    async confirmDelete() {
      if (!confirm(this.$t('firewall.confirm-delete', [this.firewall.name]))) {
        return;
      }

      await api.delete('user/firewalls/' + this.firewall.id);
      await this.$router.push({name: 'Firewall'});
    },
    selectTab(tab) {
      this.currentTab = tab;
    },
    notifyOnDirty(e = null) {
      if (!this.isDirty) {
        // Cool! All clean
        return;
      }

      const confirmationMessage = this.$t('servers.fw.quit-changes');

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    },
    syncProperties() {
      if (!this.shouldSyncProperty || !globalData.products || !this.firewall || globalData.products.length === 0 || !this.productId) {
        return
      }

      const product = globalData.products.find(p => p.id === this.productId);
      if (product) {
        this.firewall.assigned_customer_products.push({
          id: this.productId,
          name: product.settings.name,
          ip: product.settings.ipAddresses[0].ip,
          status: 'assigning'
        });
      }

      this.shouldSyncProperty = false
    }
  },
  computed: {
    user() {
      return globalData.user;
    },
    products() {
      return globalData.products;
    }
  },
  watch: {
    id() {
      window.location.reload()
    },
    firewall: {
      handler() {
        this.isDirty = this.liveFirewall !== JSON.stringify(this.firewall);
        this.syncProperties();
      },
      deep: true
    },
    liveFirewall() {
      this.isDirty = this.liveFirewall !== JSON.stringify(this.firewall);
    },
    products: {
      handler() {
        this.syncProperties();
      },
      immediate: true,
      deep: true
    },
  },
  components: {
    FirewallServers,
    OhButton,
    TabCard,
    Card,
    MainLayout,
    FirewallConfig
  },
}
</script>
