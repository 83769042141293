<template>
  <div>
    <div class="flex border-b border-gray-200 pb-2 mb-4">
      <h2 class="text-xl font-bold tracking-wider flex-1">{{ $t('servers.nw.firewall-title') }}</h2>
    </div>

    <div class="mb-10">
      <p class="text-gray-500 text-sm">{{ $t('servers.nw.firewall-teaser') }}</p>

      <div class="p-3 md:flex leading-8">
        <div class="md:flex">
          <div class="font-bold pr-4 w-48">{{ $t('servers.nw.firewall-status') }}:</div>
          <div class="flex-1">
            <span v-if="product.firewall">
              <i class="fas fa-circle text-green-500 mr-1"></i>
              {{ $t('servers.nw.firewall-status-active') }}
            </span>
            <span v-else>
              <i class="fas fa-circle text-yellow-500 mr-1"></i>
              {{ $t('servers.nw.firewall-status-inactive') }}
            </span>
          </div>
        </div>
      </div>
      <div class="px-3 md:flex mb-6 leading-8">
        <div class="md:flex">
          <div class="font-bold pr-4 w-48">{{ $t('servers.nw.firewall-assigned') }}:</div>
          <div class="flex-1">
            <span v-if="product.firewall">
              <router-link :to="'/firewall/' + product.firewall.id" class="underline">{{ product.firewall.name }}</router-link>
            </span>
            <span v-else class="italic">{{ $t('servers.nw.firewall-not-assigned') }}</span>
          </div>
        </div>
      </div>

      <div v-if="product.firewall">
        <oh-button type="danger-outline" @click="confirmDisableFirewall()">{{ $t('servers.nw.firewall-remove') }}</oh-button>
      </div>
      <div v-else>
        <oh-button type="primary" @click="openAssignFirewallModal()">{{ $t('servers.nw.firewall-assign') }}</oh-button>
      </div>
    </div>

    <div class="flex border-b border-gray-200 pb-2 mb-4">
      <h2 class="text-xl font-bold tracking-wider flex-1">{{ $t('servers.nw.general-title') }}</h2>
      <a :href="$t('help-link') + 'configuration-network'" target="_blank">
        <i class="far fa-question-circle text-2xl mr-2 text-oh-red"></i>
      </a>
    </div>

    <div class="p-3 md:flex mb-10 leading-8">
<!--      <div class="md:flex mr-14">-->
<!--        <div class="font-bold pr-4">{{ $t('servers.nw.dns') }}:</div>-->
<!--        <div class="flex-1 font-mono">116.202.140.162<br/>2a01:4f8:d1:b100::2</div>-->
<!--      </div>-->
      <div class="md:flex">
        <div class="font-bold pr-4">{{ $t('servers.nw.mac') }}:</div>
        <div class="flex-1 font-mono">{{ product.settings.macAddress }}</div>
      </div>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.nw.ipv4-title') }}</h2>
    <div class="flex mb-10 overflow-auto">
      <ip-table :product="product" @change="updateList"  :addresses="ipv4Addresses" v-if="ipv4Addresses.length"></ip-table>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.nw.ipv6-title') }}</h2>
    <div class="flex pb-3 overflow-auto">
      <ip-table :product="product" @change="updateList" :addresses="ipv6Addresses" :expandable="true" v-if="ipv4Addresses.length"></ip-table>
    </div>

    <modal :show="showAssignFirewallModal" @close="showAssignFirewallModal = false">
      <template slot="title">
        {{ $t('servers.assign-fw-modal.title') }}
      </template>
      <div v-if="fwError" class="bg-red-100 p-3 mb-6">
        {{ fwError }}
      </div>
      <p class="text-gray-500 text-sm">{{ $t('servers.assign-fw-modal.teaser') }}</p>

      <div class="mb-8">
        <oh-select id="firewall-select" v-model="selectedFirewall" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          <option value="">{{ $t('servers.assign-fw-modal.select-placeholder') }}</option>
          <option v-for="firewall in userFirewalls" :key="firewall.id" :value="firewall.id">
            {{ firewall.name }} - {{ $t('servers.assign-fw-modal.rules', [firewall.rules.length]) }}
          </option>
        </oh-select>
      </div>

      <div class="flex">
        <div class="flex-1">
          <oh-button type="default" @click="createNewFirewall()">
          <i class="fas fa-plus pr-1"></i>
          {{ $t('servers.assign-fw-modal.create-button') }}
        </oh-button>
        </div>
        <div>
          <oh-button :type="selectedFirewall ? 'primary' : 'disabled'" @click="assignFirewall()">
            {{ $t('servers.assign-fw-modal.assign-button') }}
          </oh-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import IpTable from "@/components/Servers/Network/IpTable";
import {api} from "@/api";
import OhButton from "@/components/OhButton.vue";
import Modal from "@/components/Modal.vue";
import OhSelect from "@/components/OhSelect.vue";
import {globalData} from "@/globalData";

export default {
  components: {OhSelect, Modal, OhButton, IpTable},
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      showAssignFirewallModal: false,
      fwError: null,
      selectedFirewall: null,
      userFirewalls: [],
      ipAddresses: []
    }
  },
  mounted() {
    this.updateList();
  },
  methods: {
    async updateList() {
      let response = await api.get('user/products/' + this.product.id + '/ip');
      this.ipAddresses = response.data;
    },
    async openAssignFirewallModal() {
      this.fwError = null;
      this.selectedFirewall = null;
      this.showAssignFirewallModal = true;
      let response = await api.get('user/firewalls');
      this.userFirewalls = response.data;
    },
    async assignFirewall() {
      if (!this.selectedFirewall) {
        return;
      }

      let response = await api.put('user/products/' + this.product.id + '/firewall', {firewall_id: this.selectedFirewall});
      if (response.status === 204) {
        this.showAssignFirewallModal = false;
        this.product.firewall = this.userFirewalls.find(fw => fw.id === this.selectedFirewall);
      } else {
        this.fwError = this.$t('servers.assign-fw-modal.error');
      }
      // reload products to update the list of assigned products
      globalData.products = (await api.get('user/products')).data;
    },
    createNewFirewall() {
      this.$router.push({name: 'CreateFirewall', params: {productId: this.product.id}});
    },
    async confirmDisableFirewall() {
      if (!confirm(this.$t('servers.nw.firewall-remove-confirm'))) {
        return
      }

      let response = await api.delete('user/products/' + this.product.id + '/firewall');
      if (response.status === 204) {
        this.product.firewall = null;
      } else {
        alert(this.$t('servers.nw.firewall-remove-error'));
      }
      // reload products to update the list of assigned products
      globalData.products = (await api.get('user/products')).data;
    }
  },
  computed: {
    ipv4Addresses() {
      return this.ipAddresses.filter(ip => ip.isIpV4);
    },
    ipv6Addresses() {
      return this.ipAddresses.filter(ip => !ip.isIpV4);
    }
  }
}
</script>

<style scoped>

</style>