<template>
  <main-layout :loading="loading">
    <template slot="title">
      {{ $t('firewall.headline') }}
    </template>

    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card class="w-full">
          <template slot="title">
            {{ $t('firewall.list-headline') }}
          </template>
          <p class="text-gray-500 text-sm">{{ $t('firewall.list-teaser') }}</p>

          <div class="mt-8">
            <oh-button @click="createFirewall()" type="primary" class="mt-5">
              <i class="fas fa-plus pr-1"></i>
              {{ $t('firewall.button-create') }}
            </oh-button>
          </div>

          <table class="text-left w-full mt-5">
            <thead>
            <tr class="border-b border-gray-400">
              <th></th>
              <th class="px-3 py-3">{{ $t('firewall.name') }}</th>
              <th class="px-3 py-3 hidden md:table-cell">{{ $t('firewall.rules') }}</th>
              <th class="px-3 py-3 hidden md:table-cell">{{ $t('firewall.assigned-servers') }}</th>
              <th class="px-3 py-3">{{ $t('firewall.actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="firewall in firewalls" :key="firewall.id"
                class="hover:bg-gray-100 border-b border-gray-200">
              <td class="px-3 py-3 w-10"><input type="checkbox" v-model="selectedFirewalls" :value="firewall.id"></td>
              <td class="px-3 py-3 cursor-pointer" @click="openFirewall(firewall)">{{ firewall.name }}</td>
              <td class="px-3 py-3 hidden md:table-cell">{{ firewall.rules.length }}</td>
              <td class="px-3 py-3 hidden md:table-cell">
                <span v-if="firewall.assigned_customer_products.length === 0" class="text-gray-500">
                  <i class="fas fa-circle text-red-500 mr-2"></i>
                  {{ $t('firewall.servers-none') }}
                </span>
                <span v-else-if="firewall.assigned_customer_products.length === 1" class="underline">
                  <i class="fas fa-circle text-green-500 mr-2"></i>
                  <router-link :to="'/servers/' + firewall.assigned_customer_products[0].id">{{ firewall.assigned_customer_products[0].name }}</router-link>
                </span>
                <span v-else class="underline">
                  <i class="fas fa-circle text-green-500 mr-2"></i>
                  <router-link :to="'/firewall/' + firewall.id + '#servers'">{{ $t('firewall.servers-count', [firewall.assigned_customer_products.length]) }}</router-link>
                </span>
              </td>
              <td class="px-3 py-3">
                <oh-button @click="openFirewall(firewall)" type="primary" class="mr-3">{{ $t('firewall.button-edit') }}</oh-button>
                <oh-button @click="confirmDelete(firewall)" type="danger-outline">{{ $t('firewall.button-delete') }}</oh-button>
              </td>
            </tr>
            <tr v-if="firewalls.length === 0" class="hover:bg-gray-100 border-b border-gray-200">
              <td class="px-3 py-3" colspan="4">{{ $t('firewall.no-firewalls') }}</td>
            </tr>
            </tbody>
          </table>
          <div v-if="selectedFirewalls.length" class="mt-8">
            <oh-button @click="confirmDeleteSelected()" type="danger-outline">{{ $t('firewall.button-delete-selected') }}</oh-button>
          </div>
        </card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import {globalData} from "@/globalData";
import {api} from "@/api";
import Card from "@/components/Card.vue";
import OhButton from "@/components/OhButton.vue";

export default {
  props: {
  },
  data() {
    return {
      firewalls: [],
      selectedFirewalls: [],
      loading: true
    };
  },
  mounted() {
    globalData.activeRoute = 'firewall';
    this.loadFirewalls();
  },
  methods: {
    async loadFirewalls() {
      this.firewalls = (await api.get('user/firewalls')).data;
      this.loading = false;
      // reload products to update the list of assigned products
      globalData.products = (await api.get('user/products')).data;
    },
    createFirewall() {
      if (this.user.status !== 'active') {
        alert(this.$t('firewall.create-error'));
        return;
      }

      this.$router.push({name: 'CreateFirewall'});
    },
    openFirewall(firewall) {
      this.$router.push({name: 'EditFirewall', params: {id: firewall.id}});
    },
    async confirmDelete(firewall) {
      if (!confirm(this.$t('firewall.confirm-delete', [firewall.name]))) {
        return;
      }

      this.loading = true;

      await api.delete('user/firewalls/' + firewall.id);
      await this.loadFirewalls();
    },
    async confirmDeleteSelected() {
      if (!confirm(this.$t('firewall.confirm-delete-selected', [this.selectedFirewalls.length]))) {
        return;
      }

      this.loading = true;

      for (let id of this.selectedFirewalls) {
        await api.delete('user/firewalls/' + id);
      }

      this.selectedFirewalls = [];

      await this.loadFirewalls();
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  },
  components: {
    OhButton,
    Card,
    MainLayout
  },
}
</script>
