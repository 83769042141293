<template>
  <main-layout :loading="loading">
    <template slot="title">
      {{ $t('subscriptions.headline') }}
    </template>
    <div v-if="!loading" class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card v-if="thanks && subscription.status !== 'unverified'">
          <template slot="title">{{ $t('subscriptions.payment-success') }}</template>
          <div v-if="subscription.status !== 'active'">
            <p class="mb-4">{{ $t('subscriptions.payment-success-message') }}</p>
            <hr>
            <p class="mt-4 leading-7">{{ $t('subscriptions.payment-success-note') }}</p>
          </div>
          <div v-else>
            <p class="mb-7">{{ $t('subscriptions.subscription-active-message') }}</p>
            <oh-button type="primary" @click="openProduct(null)">{{ $t('subscriptions.subscription-active-button') }}</oh-button>
            <p class="mt-4 italic">{{ $t('subscriptions.subscription-active-info') }}</p>
          </div>
        </card>
        <card v-if="subscription.status === 'unverified'">
          <template slot="title">{{ $t('subscriptions.verification-required') }}</template>
          <div v-if="user.status !== 'verified'">
            <p class="mb-4">{{ $t('subscriptions.verification-required-message') }}</p>
            <p class="mb-8">{{ $t('subscriptions.verification-required-note') }}</p>
            <oh-button type="primary" @click="startVerification()">{{ $t('subscriptions.verification-required-button') }}</oh-button>
          </div>
          <div v-else>
            <p class="mb-8">{{ $t('subscriptions.verification-waiting-message') }}</p>
          </div>
        </card>
        <card>
          <template slot="title">{{ $t('subscriptions.overview-headline', [subscription.order_id]) }}</template>
          <div class="lg:flex">
            <div class="flex-1 mb-5">
              <i class="fas mr-4 text-xl" :class="{
                'fa-pause-circle': subscription.status === 'paused',
                'fa-hourglass': ['new', 'incomplete'].includes(subscription.status),
                'fa-exclamation-triangle': subscription.status === 'unverified',
                'fa-check': subscription.status === 'active',
                'fa-times-circle': subscription.status === 'cancelled',
              }"></i>
              <span class="text-gray-500">{{ $t('subscriptions.your-order') }}</span>
              <span class="ml-2 text-xl font-bold" :class="{
                'text-red-500': subscription.status === 'paused',
                'text-yellow-600': ['new', 'incomplete', 'unverified'].includes(subscription.status),
                'text-green-500': subscription.status === 'active',
                'text-gray-500': subscription.status === 'cancelled',
              }">{{ $t('subscriptions.status.' + subscription.status) }}</span>
            </div>
            <div class="flex-1 mb-5">
              <div class=" p-1 rounded-lg shadow text-white px-4 w-min whitespace-nowrap" :class="{
                  'bg-yellow-500': subscription.payment_status === 'waiting',
                  'bg-red-500': subscription.payment_status === 'overdue',
                  'bg-green-500': subscription.payment_status === 'paid',
                }">
                <i class="fas mr-2 text-xl" :class="{
                  'fa-hourglass': subscription.payment_status === 'waiting',
                  'fa-exclamation-triangle': subscription.payment_status === 'overdue',
                  'fa-euro-sign': subscription.payment_status === 'paid',
                }"></i>
                <span class="ml-1 text-lg">
                  {{ $t('subscriptions.payment-status.' + subscription.payment_status) }}
                </span>
              </div>
            </div>
          </div>
          <div class="lg:flex">
            <div class="flex-1 mb-5">
              <i class="fas fa-clock mr-2 text-xl"></i> <span class="text-gray-500">
              {{ $t('subscriptions.ordered-at') }}</span>
              <span class="ml-2 text-xl font-bold">{{ subscription.created_at | formatDateTime }}</span>
            </div>
            <div class="flex-1 mb-5" v-if="['active', 'new'].includes(subscription.status) && subscription.renewal_active">
              <i class="fas fa-money-bill mr-2 text-xl"></i> <span
                class="text-gray-500">{{ $t('subscriptions.payment-due') }}</span>
              <span class="ml-2 text-xl font-bold">{{ subscription.current_period_end | formatDateTime }}</span>
            </div>
            <div class="flex-1 mb-5" v-else>
              <i class="fas fa-calendar mr-2 text-xl"></i> <span
                class="text-gray-500">{{ $t('subscriptions.ends-on') }}</span>
              <span class="ml-2 text-xl font-bold">{{ subscription.current_period_end | formatDateTime }}</span>
            </div>
          </div>
          <div class="lg:flex">
            <div class="flex-1 mb-5 lg:mb-0" v-if="subscription.renewal_active">
              <i class="fas fa-redo mr-2 text-xl"></i> <span class="text-gray-500">{{
                $t('subscriptions.renews', [
                  $t('subscriptions.renews-' + subscription.term)
                ])
              }}</span>
              <span class="ml-2 text-xl font-bold">{{ subscription.renewal_at | formatDate }}</span>
            </div>
            <div class="flex-1 mt-3 text-right" v-if="canManageSubscriptions && subscription.renewal_active && subscription.payment_status === 'paid'">
              <oh-button type="danger-outline" @click="showCancelPopup = true">{{ $t('subscriptions.cancel') }}</oh-button>
            </div>
            <div class="flex-1 mt-3 text-right" v-if="canManageSubscriptions && subscription.renewal_active && subscription.payment_status === 'overdue'">
              <oh-button type="danger-outline" @click="showCancelNotPossiblePopup = true">{{ $t('subscriptions.cancel') }}</oh-button>
            </div>
            <div class="flex-1 mt-3 text-right" v-if="canManageSubscriptions && !subscription.renewal_active && subscription.status === 'active' && subscription.can_reactivate">
              <oh-button type="warning" @click="showReactivatePopup = true">{{ $t('subscriptions.reactivate') }}</oh-button>
            </div>
          </div>
        </card>
        <h2 class="text-2xl font-bold mb-5 mt-14 block">{{ $t('subscriptions.included-headline') }}</h2>
        <div class="flex flex-wrap">
          <div class="md:w-1/3 pr-4" v-for="(product, k) in subscription.products"
               :key="product.id"
               @click="openProduct(k)">
            <card class="cursor-pointer hover:shadow-xl">
              <template slot="title">
                <span v-if="product.pivot && product.pivot.amount > 1">{{ product.pivot.amount }}x {{ product.name }}</span>
                <span v-else-if="subscription.customer_products.length && subscription.customer_products[k]">{{ subscription.customer_products[k].settings.name }}</span>
                <span v-else>{{ product.name }}</span>
              </template>
              <p class="mb-3" v-if="subscription.customer_products.length">{{ $t('subscriptions.access-hint') }}</p>
              <div v-if="product.category === 'primary' && product.features.length">
                <p class="font-bold mb-2">{{ $t('subscriptions.features') }}</p>
                <ul class="list-disc ml-4 leading-6">
                  <li v-for="feature in product.features" :key="feature">{{ feature.replaceAll('*', '') }}</li>
                </ul>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <modal :show="showCancelPopup" @close="showCancelPopup = false">
      <template slot="title">
        {{ cancellationStep !== 3 ? $t('subscriptions.cancel-title') : $t('subscriptions.cancel-confirmed-title') }}
      </template>
      <div v-if="cancellationStep === 1">
        <div class="w-full">
          <img src="../assets/img/error.png" class="w-24 m-auto mb-5">
        </div>
        <p>{{ $t('subscriptions.cancel-question') }}</p>
        <p class="mt-5">{{ $t('subscriptions.cancel-question2') }}</p>
        <div class="flex mt-10">
          <div class="flex-1">
            <oh-button type="primary" @click="showCancelPopup = false">
              {{ $t('subscriptions.cancel-abort') }}
            </oh-button>
          </div>
          <div>
            <oh-button type="danger" @click="cancellationStep = 2">{{ $t('subscriptions.cancel-confirm') }}</oh-button>
          </div>
        </div>
      </div>
      <div v-if="cancellationStep === 2">
        <p>{{ $t('subscriptions.cancel-question-survey-text') }}</p>
        <div class="mt-5">
          <label for="reason" class="font-bold">{{ $t('subscriptions.cancel-question-survey-headline') }}</label>
          <oh-textarea v-model="cancellationReason" id="reason"></oh-textarea>
        </div>
        <div class="flex mt-10">
          <div class="flex-1">
            <oh-button type="primary" @click="abortCancellation">
              {{ $t('subscriptions.cancel-abort') }}
            </oh-button>
          </div>
          <div>
            <oh-button type="danger" @click="cancelSubscription">{{ $t('subscriptions.cancel-confirm') }}</oh-button>
          </div>
        </div>
      </div>
      <div v-if="cancellationStep === 3">
        <p class="mb-10">{{ $t('subscriptions.cancel-confirmation') }}</p>
        <div class="text-right">
          <oh-button @click="showCancelPopup = false">{{ $t('subscriptions.close') }}</oh-button>
        </div>
      </div>
    </modal>

    <modal :show="showCancelNotPossiblePopup" @close="showCancelNotPossiblePopup = false">
      <template slot="title">
        {{$t('subscriptions.cancel-not-possible-title') }}
      </template>
      <div class="flex">
        <div class="mr-5 mt-5">
          <img src="../assets/img/warning.png" class="w-24 m-auto">
        </div>
        <div class="flex-1">
          <p>{{ $t('subscriptions.cancel-not-possible-text') }}</p>
          <p class="mt-5">{{ $t('subscriptions.cancel-not-possible-text2') }}</p>
        </div>
      </div>
      <div class="mt-10 text-center w-full">
        <oh-button type="primary" @click="openInvoices">
          {{ $t('subscriptions.cancel-not-possible-button') }}
        </oh-button>
      </div>
    </modal>
    <modal :show="showReactivatePopup" @close="showReactivatePopup = false">
      <template slot="title">
        {{ reactivationStep !== 2 ? $t('subscriptions.reactivate-title') : $t('subscriptions.reactivate-confirmed-title') }}
      </template>
      <div v-if="reactivationStep === 1">
        <p>{{ $t('subscriptions.reactivate-question') }}</p>
        <p class="mt-5">{{ $t('subscriptions.reactivate-question2') }}</p>
        <div class="flex mt-10">
          <div class="flex-1">
            <oh-button type="default" @click="showReactivatePopup = false">
              {{ $t('subscriptions.reactivate-abort') }}
            </oh-button>
          </div>
          <div>
            <oh-button type="primary" @click="reactivateSubscription()">{{ $t('subscriptions.reactivate-confirm') }}</oh-button>
          </div>
        </div>
      </div>
      <div v-if="reactivationStep === 2">
        <p class="mb-10">{{ $t('subscriptions.reactivate-confirmation') }}</p>
        <div class="text-right">
          <oh-button @click="showReactivatePopup = false">{{ $t('subscriptions.close') }}</oh-button>
        </div>
      </div>
    </modal>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import {globalData} from "@/globalData";
import {api} from "@/api";
import Card from "@/components/Card";
import OhButton from "@/components/OhButton";
import Modal from "@/components/Modal";
import OhTextarea from "@/components/OhTextarea";

export default {
  props: {
    id: {
      required: true,
    }
  },
  data() {
    return {
      subscription: {},
      interval: null,
      showCancelPopup: false,
      showCancelNotPossiblePopup: false,
      showReactivatePopup: false,
      cancellationStep: 1,
      reactivationStep: 1,
      loadingCancellation: false,
      cancellationReason: ''
    };
  },
  mounted() {
    this.interval = setInterval(this.refreshSubscription, 1000 * 15);

    this.refreshSubscription();

    globalData.activeRoute = 'invoices';
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async openInvoices() {
      let response = await api.post('user/payments/manage');
      window.location.href = response.data.url;
    },
    abortCancellation() {
      this.showCancelPopup = false
      this.cancellationStep = 1
    },
    async refreshSubscription() {
      try {
        this.subscription = (await api.get('user/subscriptions/' + this.id)).data;
      } catch (e) {
        if (e.response.status === 401) {
          window.location.reload();
        }
        if ([404, 403].includes(e.response.status)) {
          globalData.showError = e.response.status;
        }
      }
    },
    startVerification() {
      window.open(process.env.VUE_APP_WEBSITE_URL + 'api/identity/verify/' + this.user.id, '_blank').focus();
    },
    openProduct(key) {
      if (!this.subscription.customer_products.length) {
        return;
      }

      let customerProduct = this.subscription.customer_products[0] || false;
      if (key === null) {
        let primaryProducts = this.subscription.customer_products.find(p => p.type !== 'smb_storage')
        if (primaryProducts.length >= 1) {
          customerProduct = primaryProducts[0];
        }
      } else {
        customerProduct = this.subscription.customer_products[key] || false;
      }

      if (customerProduct && customerProduct.type !== 'smb_storage') {
        this.$router.push({name: 'Servers', params: {id: customerProduct.id}, hash: '#access'});
      } else if (customerProduct) {
        this.$router.push({name: 'Storage'});
      }
    },
    async cancelSubscription() {
      if (this.loadingCancellation) {
        return;
      }
      this.loadingCancellation = true;
      await api.delete('user/subscriptions/' + this.id, {data: {comment: this.cancellationReason}});
      this.loadingCancellation = false;
      this.cancellationStep = 3;
      await this.refreshSubscription()
    },
    async reactivateSubscription() {
      if (this.loadingCancellation) {
        return;
      }
      this.loadingCancellation = true;
      try {
        await api.post('user/subscriptions/' + this.id + '/reactivate');
      } catch (e) {
        this.loadingCancellation = false;
        alert('Reactivation failed. Please contact our support. We are sorry for the inconvenience.')
        return;
      }
      this.loadingCancellation = false;
      this.reactivationStep = 2;
      await this.refreshSubscription()
    }
  },
  computed: {
    loading() {
      return typeof this.subscription.id === 'undefined';
    },
    user() {
      return globalData.user;
    },
    thanks() {
      return this.$route.query.state === 'thanks';
    },
    canManageSubscriptions() {
      return this.user && (this.user.account_owner || this.user.permissions.filter(p => p.type === 'subscriptions').length > 0)
    }
  },
  watch: {
    'subscription.status'(current, old) {
      if (old) {
        window.location.reload();
      }
    }
  },
  components: {
    OhTextarea,
    Modal,
    OhButton,
    Card,
    MainLayout
  },
}
</script>
