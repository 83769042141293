<template>
  <transition name="fade">
    <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-transp z-40 overflow-y-auto" v-if="show"
         @click.prevent.self="tryClose">
      <card class="mt-32 lg:mt-48 z-50 mx-auto max-w-modal lg:w-3/4 w-11/12">
        <template slot="title">
          <span class="block flex">
            <span class="block flex-1"><slot name="title"></slot></span>
            <span class="block cursor-pointer" v-if="canDismiss" @click.prevent="tryClose">
              <i class="fas fa-times"></i>
            </span>
          </span>
        </template>
        <slot></slot>
      </card>
    </div>
  </transition>
</template>

<script>
import Card from "@/components/Card";

export default {
  props: {
    show: {required: true},
    canDismiss: {default: true}
  },
  components: {Card},
  methods: {
    tryClose() {
      if (!this.canDismiss) {
        return;
      }

      this.$emit('close');
    }
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  },
  watch: {
    show() {
      if (this.show) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }
}
</script>

<style scoped>

</style>