import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard";
import Servers from "@/views/Servers";
import Subscriptions from "@/views/Subscriptions";
import Support from "@/views/Support";
import Account from "@/views/Account";
import ActivityLog from "@/views/ActivityLog";
import Affiliate from "@/views/Affiliate";
import Monitoring from "@/views/Monitoring";
import NotFound from "@/views/NotFound";
import InvoicesPayment from "@/views/InvoicesPayment";
import KVM from "@/views/KVM";
import Storage from "@/views/Storage";
import UserManagement from "@/views/UserManagement";
import Firewall from "@/views/Firewall.vue";
import EditFirewall from "@/views/EditFirewall.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/servers/:id',
        name: 'Servers',
        component: Servers,
        props: true
    },
    {
        path: '/subscriptions/:id',
        name: 'Subscriptions',
        component: Subscriptions,
        props: true
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
    },
    {
        path: '/storage',
        name: 'Storage',
        component: Storage,
    },
    {
        path: '/firewall',
        name: 'Firewall',
        component: Firewall,
    },
    {
        path: '/firewall/create/:productId?',
        name: 'CreateFirewall',
        component: EditFirewall,
        props: true
    },
    {
        path: '/firewall/:id',
        name: 'EditFirewall',
        component: EditFirewall,
        props: true
    },
    {
        path: '/monitoring',
        name: 'Monitoring',
        component: Monitoring,
    },
    {
        path: '/log',
        name: 'ActivityLog',
        component: ActivityLog,
    },
    {
        path: '/affiliate',
        name: 'Affiliate',
        component: Affiliate,
    },
    {
        path: '/user-management',
        name: 'UserManagement',
        component: UserManagement,
    },
    {
        path: '/invoices',
        name: 'InvoicesPayment',
        component: InvoicesPayment,
    },
    {
        path: '/kvm/:id',
        name: 'KVM',
        component: KVM
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
