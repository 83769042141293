<template>
  <a
      @click="onClick"
      :href="href || '#'"
      role="button"
      class="px-6 py-2 rounded-lg transition"
      :class="[
          type === 'default' ? 'border bg-white border-gray-300 hover:bg-gray-300': '',
          type === 'danger' ? 'bg-oh-red text-white hover:bg-red-900': '',
          type === 'danger-outline' ? 'border border-oh-red text-oh-red bg-white': '',
          type === 'warning' ? 'bg-oh-yellow text-black hover:bg-yellow-500': '',
          type === 'primary' ? 'bg-oh-gradient text-white': '',
          type === 'disabled' ? 'bg-gray-200 text-gray-700 cursor-not-allowed': 'cursor-pointer hover:shadow-xl',
          buttonClass
      ]"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    type: {
      default: 'default'
    },
    buttonClass: {
      default: ''
    },
    href: {
      default: null
    }
  },
  methods: {
    onClick(e) {
      if (this.href) {
        return;
      }

      e.preventDefault();

      this.$emit('click');
    }
  }
}
</script>

<style scoped>

</style>