<template>
  <div>
<!--    <div class="mb-6">-->
<!--      <oh-button @click="createDefaultRules" button-class="w-full">{{ $t('servers.fw.restore-default') }}</oh-button>-->
<!--    </div>-->

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.fw.inbound') }}</h2>
    <div class="mb-10">
      <p class="text-gray-500 text-sm">{{ $t('servers.fw.inbound-description') }}</p>
      <div class="mt-5 overflow-auto">
        <table class="w-full rule-table">
          <thead>
          <tr class="text-left bg-gray-100">
            <th class="p-4 w-2/12">{{ $t('servers.fw.rule.type') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.protocol') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.port') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv4-source') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv6-source') }}</th>
            <th class="w-2/12" colspan="2">{{ $t('servers.fw.rule.comment') }}</th>
          </tr>
          </thead>
          <tbody>
          <rule ruleset="inbound" v-for="rule in inboundRules" :key="rule.id" :rule="rule" @remove="removeRule(rule)"></rule>
          <tr v-if="!inboundRules.length">
            <td colspan="7" class="p-4 text-oh-red">
              {{ $t('servers.fw.all-traffic-notice') }}
            </td>
          </tr>
          <rule ruleset="inbound" :empty="true" :rule="{}" @created="createRule($event, 'inbound')"></rule>
          </tbody>
        </table>
      </div>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.fw.outbound') }}</h2>
    <div class="mb-3">
      <p class="text-gray-500 text-sm">{{ $t('servers.fw.outbound-description') }}</p>
      <div class="mt-5 overflow-auto">
        <table class="w-full rule-table">
          <thead>
          <tr class="text-left bg-gray-100">
            <th class="p-4 w-2/12">{{ $t('servers.fw.rule.type') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.protocol') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.port') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv4-destination') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv6-destination') }}</th>
            <th class="w-2/12" colspan="2">{{ $t('servers.fw.rule.comment') }}</th>
          </tr>
          </thead>
          <tbody>
          <rule ruleset="outbound" v-for="rule in outboundRules" :key="rule.id" :rule="rule" @remove="removeRule(rule)"></rule>
          <tr v-if="!outboundRules.length">
            <td colspan="7" class="p-4 text-oh-red">
              {{ $t('servers.fw.all-traffic-notice') }}
            </td>
          </tr>
          <rule ruleset="outbound" :empty="true" :rule="{}" @created="createRule($event, 'outbound')"></rule>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-6 mb-2 text-gray-500 text-sm">
      <strong>{{ $t('servers.fw.note-title') }}:</strong> {{ $t('servers.fw.note') }}
    </div>
    <div v-if="isDirty" class="text-oh-red mt-6">
      <i class="fas fa-exclamation-triangle text-oh-red"></i>
      {{ $t('firewall.edit.unsaved-changes') }} {{ $t('firewall.edit.unsaved-changes-hint') }}
    </div>
  </div>
</template>

<script>

import Rule from "@/components/Firewall/Rule";

export default {
  components: {Rule},
  props: {
    firewall: {
      required: true
    },
    isDirty: {
      required: true
    }
  },
  methods: {
    createRule(event, type) {
      this.firewall.rules.push({
        type: type,
        comment: null,
        id: null,
        ipv4_addresses: null,
        ipv6_addresses: null,
        position: this.firewall.rules.length ? this.firewall.rules[this.firewall.rules.length - 1].position + 1 : 1,
        ...event
      });
    },
    removeRule(rule) {
      this.firewall.rules.splice(this.firewall.rules.indexOf(rule), 1);
    },
    createDefaultRules() {
      this.firewall.rules = [
        {comment: 'Default', ports: '22', position: 1, protocol: 'tcp', type: 'inbound'},
        {comment: 'Default', ports: '88,5900', position: 2, protocol: 'tcp', type: 'inbound'},
        {comment: 'Default', ports: null, position: 3, protocol: 'icmp', type: 'outbound'},
        {comment: 'Default', ports: null, position: 4, protocol: 'tcp', type: 'outbound'},
        {comment: 'Default', ports: null, position: 5, protocol: 'udp', type: 'outbound'}
      ];
    },
  },
  computed: {
    inboundRules() {
      return this.firewall.rules.filter(rule => rule.type === 'inbound');
    },
    outboundRules() {
      return this.firewall.rules.filter(rule => rule.type === 'outbound');
    },
  }
}
</script>

<style scoped>
  .rule-table {
    min-width: 800px;
  }
</style>