<template>
  <div>
    <div class="flex h-20 md:justify-center items-center text-3xl bg-gray-transp">
      <div class="px-10 md:hidden cursor-pointer" role="button" aria-label="Expand Menu" @click="showMenu = !showMenu">
        <i class="fas fa-bars"></i>
      </div>
      <div>
        <img src="/images/logo_cp.png" alt="OakHost" title="OakHost">
      </div>
    </div>
    <div role="navigation" class="md:block pb-4" :class="showMenu ? '' : 'hidden'">
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'dashboard'}">
        <router-link to="/" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-home"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.dashboard') }}</span>
        </router-link>
      </div>
      <div v-if="products">
        <a href="#" @click.prevent="showServers = !showServers"
           class="hover:shadow-inner hover:bg-gray-transp-1 flex pr-8 py-4">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-server"></i>
          </span>
          <span class="block mt-1 flex-1">{{ $t('nav.servers') }}</span>
          <span class="block text-xl mt-1">
            <i class="fas" :class="[showServers ? 'fa-caret-up' : 'fa-caret-down']"></i>
          </span>
        </a>
        <div class="relative w-full" v-if="showServers">
          <div class="absolute w-full h-8 bg-glow-gradient-top pointer-events-none transition-opacity duration-300" :class="{'opacity-0': !showTopScrollIndicator}"></div>
        </div>
        <ul v-if="showServers" class="mb-4 border-t border-b border-gray-600 overflow-x-auto max-h-500px" @scroll="onScroll" ref="serverList">
          <li v-for="product in products" :key="product.id"
              class="hover:bg-gray-transp border-b border-gray-800 w-full block shadow-inner"
              :class="activeRoute === 'server#' + product.id ? 'bg-gray-transp-0': 'bg-gray-transp-1'">
            <router-link :to="'/servers/' + product.id" class="block pl-10 py-4">
              <i class="fab mr-6" :class="{
                'fa-apple': product.type === 'mac',
                'fa-ubuntu': product.type === 'linux_root',
              }"></i>
              {{ shortenName(product.settings.name) }}
            </router-link>
          </li>
          <li v-if="products.length === 0" class="hover:bg-gray-transp border-b border-gray-800 w-full block shadow-inner block pl-10 py-4">
            {{ $t('nav.no-servers') }}
          </li>
        </ul>

        <div class="relative w-full" v-if="showServers">
          <div class="absolute w-full -top-12 h-8 bg-glow-gradient-bottom pointer-events-none transition-opacity duration-300" :class="{'opacity-0': !showBottomScrollIndicator}"></div>
        </div>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1"
           :class="{'bg-gray-transp-1': activeRoute === 'firewall'}">
        <router-link to="/firewall" class="flex py-4 pr-8">
          <span class="text-xl block w-10 ml-10">
            <i class="fas fa-shield-alt"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.firewall') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1"
           :class="{'bg-gray-transp-1': activeRoute === 'storage'}">
        <router-link to="/storage" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-hdd"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.storage') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1"
           :class="{'bg-gray-transp-1': activeRoute === 'monitoring'}">
        <router-link to="/monitoring" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-desktop"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.monitoring') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'invoices'}" v-if="canManageSubscriptions">
        <router-link to="/invoices" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-file-invoice"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.invoices') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'account'}">
        <router-link to="/account" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-user"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.account') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'support'}">
        <router-link to="/support" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-info-circle"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.support') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'log'}">
        <router-link to="/log" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-list"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.log') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'user-management'}"
           v-if="user.company_name && user.account_owner && hasActiveSubscription">
        <router-link to="/user-management" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-users"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.user-management') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1" :class="{'bg-gray-transp-1': activeRoute === 'affiliate'}"
        v-if="user && user.account.settings.affiliate_ref">
        <router-link to="/affiliate" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-hands-helping"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.affiliate') }}</span>
        </router-link>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1 border-t border-gray-500 mt-1">
        <a href="https://www.oakhost.net" target="_blank" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-external-link-alt"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.website') }}</span>
        </a>
      </div>
      <div class="hover:shadow-inner hover:bg-gray-transp-1 mt-1">
        <a href="https://status.oakhost.net" target="_blank" class="flex py-4 pr-8">
          <span class="text-xl block w-10 mt-1 ml-10">
            <i class="fas fa-external-link-alt"></i>
          </span>
          <span class="block mt-1">{{ $t('nav.status') }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {globalData} from "@/globalData";
import Vue from "vue";

export default {
  data() {
    return {
      showServers: true,
      showMenu: false,
      showBottomScrollIndicator: false,
      showTopScrollIndicator: false
    }
  },
  methods: {
    shortenName(name) {
      const shortName = name.slice(0, 20);
      if (shortName === name) {
        return name;
      }

      return shortName + '...';
    },
    onScroll()
    {
      if (this.$refs.serverList.scrollHeight <= this.$refs.serverList.clientHeight) {
        // No scrollbar, no problem
        return
      }
      const isTop = this.$refs.serverList.scrollTop === 0
      const isBottom = this.$refs.serverList.scrollTop + this.$refs.serverList.clientHeight === this.$refs.serverList.scrollHeight
      this.showTopScrollIndicator = !isTop
      this.showBottomScrollIndicator = !isBottom
    }
  },
  watch: {
    $route() {
      this.showMenu = false;
    },
    products() {
      Vue.nextTick(() => {
        this.showBottomScrollIndicator = this.$refs.serverList.scrollHeight > this.$refs.serverList.clientHeight
      })
    }
  },
  computed: {
    user() {
      return globalData.user;
    },
    products() {
      return globalData.products.filter(p => p.type !== 'smb_storage').sort((a, b) => a.settings.name.localeCompare(b.settings.name))
    },
    activeRoute() {
      return globalData.activeRoute;
    },
    canManageSubscriptions() {
      return this.user && (this.user.account_owner || this.user.permissions.filter(p => p.type === 'subscriptions').length > 0)
    },
    hasActiveSubscription() {
      for (let k in globalData.subscriptions) {
        if (['active'].includes(globalData.subscriptions[k].status)) {
          return true;
        }
      }

      return false;
    }
  }
}
</script>

<style scoped>

</style>